import React, { useEffect, useState } from "react";

import "./verify.css";

const Verify = () => {
  const apiData = {
    idHolder: {
      id: null,
      tenant: null,
      class: null,
      section: " ",
      serialNumber: null,
      firstName: null,
      fatherName: null,
      contactNumber: null,
      photo: null,
      subSection: null,
    },
    tenantName: null,
    tenantLogoUrl: null,
  };

  const [userData, setUserData] = useState(apiData);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  //https://jsnischaya-matrimony-api.azurewebsites.net/api/idholder/verify/hjlYZETdIckfFX9K9ajYondBlLVZh5hc7Wa6UU13%2bSndZuUPQWDMZhwVfGoej7FCkZ88aJPe5Z2Pl0z6Lhu%2bxQ%3d%3d
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = encodeURIComponent(params.get("id").replaceAll(" ", "+"));

    setLoading(true);
    fetch(
      `https://jsnischaya-matrimony-api.azurewebsites.net/api/idholder/verify?id=${id}`
    )
      .then((response) => response.json())
      .then((res) => {
        setUserData(res);
        setError(false);
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const {
    class: className,
    firstName,
    fatherName,
    lastName,
    designation,
    department,
    section,
    subSection,
    contactNumber,
    photo: photoURL,
  } = userData?.idHolder;
  const { tenantLogoUrl: logo } = userData;

  return (
    <section className="page-contain">
      {loading && <div>Verifying ID card..</div>}
      {error && (
        <div class="invalid-id-card">Caution! This is not a valid ID card</div>
      )}
      {userData?.idHolder?.id && (
        <a href="#" className="data-card">
          <h4 className="verify-label">
            <span>Okay! This is a valid ID Card</span>
            <div>Verified by ID Checkpost</div>
          </h4>
          <div>
            <img
              src={logo}
              height="120"
              width="110"
              style={{ marginLeft: "70px" }}
            />
          </div>

          <div>
            <img
              src={photoURL}
              height="120"
              width="110"
              style={{ marginLeft: "70px" }}
            />
          </div>
          <div className="label">
            Name: <span className="student-value">{firstName}</span>
            {lastName && <span className="label"> {lastName ?? ""}</span>}
          </div>
          {className && <div className="label">Class: {className}</div>}
          {section && (
            <div className="label">
              Section: {section} {subSection}
            </div>
          )}
          {fatherName && (
            <div className="label">Father Name: {fatherName ?? ""}</div>
          )}

          {designation && (
            <div className="label">Designation: {designation ?? ""}</div>
          )}

          {department && (
            <div className="label">Department: {department ?? ""}</div>
          )}

          <div className="label">Contact number: {contactNumber}</div>
          {/* <div className="label">{orgName}</div> */}
        </a>
      )}
    </section>
  );
};

export default function App() {
  return <Verify />;
}
